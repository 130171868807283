








































































































































import Vue from 'vue'
import { FormRef, Rule, SelectOption } from '@/models/form'
import { floatNumValidatorRule } from '../views/SmartTrading/TradesTerminal/components/TradeOrderForm/index.vue'
import StepInput from '@/components/StepInput.vue'
import OrderService from '@/services/order'
import { OrderReq } from '@/services/order/models'
import { mapMutations, mapState } from 'vuex'
import { MESSAGE_STATUS } from '@/store/modules/message/types'

export default Vue.extend({
  data(): {
    params: {
      price: number | null
      amount: number | null
      positionType: string
      leg: string
      text: string
    }
    rules: Record<string, Rule[]>
    loading: boolean
  } {
    return {
      params: {
        price: null,
        amount: null,
        positionType: '',
        leg: '',
        text: ''
      },
      loading: false,
      rules: {
        price: floatNumValidatorRule,
        amount: floatNumValidatorRule
      }
    }
  },

  components: {
    StepInput
  },

  computed: {
    ...mapState('editOrderModal', ['open', 'orderData']),

    ...mapState('exchange', ['exchange', 'pair']),

    unitStep(): number | undefined {
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!this.pair) {
        return Number(this.pair?.lotSize)
      }

      return undefined
    },

    amount(): number {
      return Number(this.params.amount)
    },

    // See: https://klaride.atlassian.net/browse/CMM-389
    isBitmexExchange(): boolean {
      return this.exchange.toUpperCase().includes('BITMEX')
    },

    bitmexLinearFalseQuanto(): boolean {
      return (
        !!this.pair &&
        this.isBitmexExchange &&
        this.pair.isQuanto.toUpperCase() === 'FALSE' &&
        this.pair.linearInverse === 'linear'
      )
    },

    bitmexInverseFalseQuanto(): boolean {
      return (
        !!this.pair &&
        this.isBitmexExchange &&
        this.pair.isQuanto.toUpperCase() === 'FALSE' &&
        this.pair.linearInverse === 'inverse'
      )
    },

    bitmexMultiplier(): number {
      if (!!this.pair && this.isBitmexExchange) {
        if (this.pair.settlementCurrency === 'XBt') {
          return this.pair.contractMultiplier * 10 ** -8
        } else if (this.pair.settlementCurrency === 'USDt') {
          return this.pair.contractMultiplier * 10 ** -6
        }
      }

      return 1
    },

    isXBTSettlement(): boolean {
      return !!this.pair && this.pair.settlementCurrency === 'XBt'
    },

    bitmexQuantoOnly(): boolean {
      return !!this.pair && this.isBitmexExchange && this.pair.isQuanto.toUpperCase() === 'TRUE'
    },

    displayedUnitSymbolString(): string {
      if (this.pair) {
        if (this.isBitmexExchange) {
          if (this.bitmexLinearFalseQuanto) {
            return this.pair.baseCurrency
          } else if (this.bitmexInverseFalseQuanto) {
            return this.pair.quoteCurrency
          } else if (this.bitmexQuantoOnly) {
            return 'Cont'
          }
          // See: https://klaride.atlassian.net/browse/CMM-389?focusedCommentId=13386
        } else if (this.isDeribitExchange) {
          return this.pair.quoteCurrency
          // See: https://klaride.atlassian.net/browse/CMM-390
        } else if (this.isBinanceFutureExchange) {
          return 'Cont'
        } else {
          return this.pair.baseCurrency
        }
      }

      return this.orderData.baseCurrency
    },

    minOrderSize(): number {
      return Number(this.pair?.minOrderSize || 0)
    },

    // See: https://klaride.atlassian.net/browse/CMM-397?focusedCommentId=13408
    positionTypeOptions(): SelectOption[] {
      return [
        {
          label: '-----------------',
          value: ''
        },
        {
          label: 'spot',
          value: 'spot'
        },
        {
          label: 'margin',
          value: 'margin'
        }
      ]
    },

    legOptions(): SelectOption[] {
      return [
        {
          label: '-----------------',
          value: ''
        },
        {
          label: 'quote',
          value: 'quote'
        },
        {
          label: 'hedge',
          value: 'hedge'
        }
      ]
    },

    // See: https://klaride.atlassian.net/browse/CMM-389?focusedCommentId=13386
    isDeribitExchange(): boolean {
      return this.exchange.toUpperCase().includes('DERIBIT')
    },

    // See: https://klaride.atlassian.net/browse/CMM-390
    isBinanceFutureExchange(): boolean {
      return (
        this.exchange.toUpperCase().includes('BINANCEFUTURE') &&
        this.pair &&
        this.pair.contractMultiplier !== '' &&
        !isNaN(Number(this.pair.contractMultiplier))
      )
    }
  },

  methods: {
    ...mapMutations('message', ['SET_MESSAGE']),

    ...mapMutations('flagTrigger', ['TOGGLE_ORDER_TRIGGER']),

    ...mapMutations('editOrderModal', ['CLOSE_EDIT_ORDER_MODAL']),

    async editOrderExchange(data: Partial<OrderReq>) {
      this.loading = true

      try {
        await OrderService.editOrderExchange(data)

        // show notification
        this.SET_MESSAGE({
          type: MESSAGE_STATUS.SUCCESS,
          message: 'Edit order success!'
        })

        // trigger reload order
        this.TOGGLE_ORDER_TRIGGER()
      } finally {
        this.loading = false
        this.CLOSE_EDIT_ORDER_MODAL()
      }
    },

    submitForm() {
      const formRef = this.$refs.editForm as FormRef

      formRef.validate((valid: boolean) => {
        if (valid) {
          const dataReq = {
            exchange: this.orderData.exchange,
            id: this.orderData.orderId,
            amount: this.orderData.side === 'BUY' ? this.amount : 0 - this.amount,
            price: Number(this.params.price),
            leg: this.params.leg,
            positionType: this.params.positionType,
            text: this.params.text
          }
          this.editOrderExchange(dataReq)
        } else {
          return false
        }
      })
    }
  },

  created() {
    this.params = {
      ...this.params,
      price: this.orderData?.price ? Number(this.orderData.price) : null,
      amount: this.orderData?.size ? Math.abs(Number(this.orderData.size)) : null,
      text: this.orderData?.text || '',
      leg: this.orderData?.leg || '',
      positionType: this.orderData?.positionType || ''
    }
  }
})
