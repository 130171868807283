































































































import Vue from 'vue'
import { mapMutations, mapState } from 'vuex'
import { cloneDeep as _cloneDeep, delay as _delay } from 'lodash'
import TradeOrders, { TableRecord } from './TradeOrders.vue'
import OrderService from '@/services/order'
import { ListOrderRes } from '@/services/order/models'
import TerminalCustomSelect from '../TradesTerminal/components/TerminalFormFilter/TerminalCustomSelect.vue'
import ExchangeService from '@/services/exchange'
import { SelectOption } from '@/models/form'
import { Exchange } from '@/services/exchange/models'
import EditOrderDialog from '@/components/EditOrderDialog.vue'
import { allExchangeOption } from '../constants'

const allStatus = {
  label: 'All',
  value: ''
}

export default Vue.extend({
  components: {
    TradeOrders,
    TerminalCustomSelect,
    EditOrderDialog
  },

  data(): {
    tableData: TableRecord[]
    loading: boolean
    accounts: Exchange[]
    filters: Record<string, any>
  } {
    return {
      tableData: [],
      loading: false,
      accounts: [],
      filters: {
        statusSelected: { ...allStatus },
        sideSelected: { ...allStatus },
        productTypeSelected: { ...allStatus },
        positionTypeSelected: { ...allStatus },
        accountSelected: allExchangeOption,
        filledSize: '',
        remainingSize: '',
        symbol: ''
      }
    }
  },

  computed: {
    ...mapState('flagTrigger', ['orderFlag']),

    ...mapState('editOrderModal', ['open']),

    accountOptions(): SelectOption[] {
      return [{ ...allExchangeOption }].concat(
        this.accounts.map((account) => ({
          label: account.name,
          value: account.name,
          img: `/images/portfolios/${account.name}.svg`
        }))
      )
    },

    statusOptions(): SelectOption[] {
      const statuses: string[] = [...new Set(this.tableData?.map((item) => item.status))] || []
      return [{ ...allStatus }].concat(
        statuses.map((status) => ({
          label: status,
          value: status
        }))
      )
    },

    sideOptions(): SelectOption[] {
      return [{ ...allStatus }].concat([
        {
          label: 'Buy',
          value: 'BUY'
        },
        {
          label: 'Sell',
          value: 'SELL'
        }
      ])
    },

    productTypeOptions(): SelectOption[] {
      const productTypes: string[] = [...new Set(this.tableData?.map((item) => item.product_type))] || []
      return [{ ...allStatus }].concat(
        productTypes.map((productType) => ({
          label: productType,
          value: productType
        }))
      )
    },

    positionTypeOptions(): SelectOption[] {
      const positionTypes: string[] = [...new Set(this.tableData?.map((item) => item.position_type))] || []
      return [{ ...allStatus }].concat(
        positionTypes.map((positionType) => ({
          label: positionType,
          value: positionType
        }))
      )
    },

    ordersFiltered(): TableRecord[] {
      if (this.tableData.length <= 0) return []

      let data: TableRecord[] = _cloneDeep(this.tableData)

      if (this.filters.accountSelected.value !== '') {
        data = data.filter((item) => item.exchange === String(this.filters.accountSelected.value))
      }

      if (this.filters.statusSelected.value !== '') {
        data = data.filter((item) => item.status === String(this.filters.statusSelected.value))
      }

      if (this.filters.symbol !== '') {
        data = data.filter((item) => item.symbol.toUpperCase().includes(this.filters.symbol.toUpperCase()))
      }

      if (this.filters.sideSelected.value !== '') {
        data = data.filter((item) => item.side === String(this.filters.sideSelected.value))
      }

      if (this.filters.productTypeSelected.value !== '') {
        data = data.filter((item) => item.product_type === String(this.filters.productTypeSelected.value))
      }

      if (this.filters.positionTypeSelected.value !== '') {
        data = data.filter((item) => item.position_type === String(this.filters.positionTypeSelected.value))
      }

      if (this.filters.filledSize !== '') {
        data = data.filter((item) => item.executed_qty === Number(this.filters.filledSize))
      }

      if (this.filters.remainingSize !== '') {
        data = data.filter((item) => String(item.size) === String(this.filters.remainingSize))
      }

      return data
    },

    exchange(): string {
      return String(this.filters.accountSelected?.value)
    }
  },

  methods: {
    ...mapMutations('editOrderModal', ['SET_EDIT_ORDER_MODAL']),

    async getAllOrders() {
      this.tableData = []
      this.loading = true

      try {
        const { data: listActiveOrder } = await OrderService.getAllOrders({ exchange: this.exchange })
        this.tableData =
          listActiveOrder?.custom?.data?.reduce((accumulator: TableRecord[], currentValue: ListOrderRes) => {
            const record: TableRecord = {
              exchange: currentValue.exchange,
              symbol: currentValue.symbol,
              side: currentValue.side === 'B' ? 'BUY' : 'SELL',
              type: currentValue.order_type,
              size: currentValue.remaining_qty,
              price: currentValue.order_price,
              triggerPrice: '',
              options: '',
              transactTime: currentValue.timestamp,
              orderId: currentValue.order_id,
              status: currentValue.status,
              qty_base_ccy_init: currentValue.qty_base_ccy_init,
              qty_quote_ccy_init: currentValue.qty_quote_ccy_init,
              qty_base_ccy_remaining: currentValue.qty_base_ccy_remaining,
              qty_quote_ccy_remaining: currentValue.qty_quote_ccy_remaining,
              qty_base_ccy_executed: currentValue.qty_base_ccy_executed,
              qty_quote_ccy_executed: currentValue.qty_quote_ccy_executed,
              leg: currentValue.leg,
              product_type: currentValue.product_type,
              base_ccy: currentValue.base_ccy,
              quote_ccy: currentValue.quote_ccy,
              position_type: currentValue.position_type,
              executed_qty: currentValue.executed_qty,
              text: currentValue.text,
              created_by: currentValue.createdBy
            }
            accumulator.push(record)

            return accumulator
          }, []) || []
      } finally {
        this.$emit('update:refresh', false)
        this.loading = false
      }
    },

    async getActiveExchanges() {
      try {
        const { data: response } = await ExchangeService.getActiveExchanges()
        if (response.success && response.data && response.data.length > 0) {
          this.accounts = response.data
        }
      } catch (_) {}
    },

    openEditModal(val: TableRecord) {
      this.SET_EDIT_ORDER_MODAL(val)
    }
  },

  watch: {
    accountSelected: {
      handler() {
        this.getAllOrders()
      },
      deep: true
    },

    orderFlag() {
      this.loading = true

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      _delay(this.getAllOrders, 10000)
    }
  },

  async created() {
    await this.getActiveExchanges()
    await this.getAllOrders()
  }
})
